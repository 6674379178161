<template>
    <div class="select-02">
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="H" label=""></el-table-column>
            <el-table-column prop="X" label="无奖"></el-table-column>
            <el-table-column prop="X2" label="中2"></el-table-column>
        </el-table>
        <div>
            <p>
                综合中奖概率：<span>{{ sumP(tableData) }}</span>
            </p>
            <p>
                综合期望：<span>{{ sumE(tableData) }}</span>
            </p>
            <p>几何分布，期望中奖所需要的次数：{{ 1 / sumP(tableData) }}</p>
            <p>
                几何分布，期望中奖所需要的次数方差：{{
                    (1 - sumP(tableData)) / sumP(tableData) ** 2
                }}
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "Select02",
    data() {
        return {
            tableData: []
        };
    },
    inject: ["getP", "sumP", "sumE"],
    created() {
        let P2 = this.getP(2, 2);
        let X = 1 - P2;
        let P = {
            H: "P(X)",
            X2: P2,
            X: X
        };
        this.tableData.push(P);

        let E = {
            H: "E(X)",
            X2: P2 * 17,
            X: X * -2
        };
        this.tableData.push(E);
    }
};
</script>
<style lang="scss" scoped></style>
