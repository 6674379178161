<template>
    <div class="probability-analysis">
        <el-tabs>
            <el-tab-pane label="选一"><Select01 /></el-tab-pane>
            <el-tab-pane label="选二"><Select02 /></el-tab-pane>
            <el-tab-pane label="选三"><Select03 /></el-tab-pane>
            <el-tab-pane label="选四"><Select04 /></el-tab-pane>
            <el-tab-pane label="选五"><Select05 /></el-tab-pane>
            <el-tab-pane label="选六"><Select06 /></el-tab-pane>
            <el-tab-pane label="选七"><Select07 /></el-tab-pane>
            <el-tab-pane label="选八"><Select08 /></el-tab-pane>
            <el-tab-pane label="选九"><Select09 /></el-tab-pane>
            <el-tab-pane label="选十"><Select10 /></el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { combinations } from "mathjs";

import Select01 from "./select-01";
import Select02 from "./select-02";
import Select03 from "./select-03";
import Select04 from "./select-04";
import Select05 from "./select-05";
import Select06 from "./select-06";
import Select07 from "./select-07";
import Select08 from "./select-08";
import Select09 from "./select-09";
import Select10 from "./select-10";
export default {
    name: "ProbabilityAnalysis",
    components: {
        Select01,
        Select02,
        Select03,
        Select04,
        Select05,
        Select06,
        Select07,
        Select08,
        Select09,
        Select10
    },
    provide: {
        getP(s, z) {
            let s_s = s - z;
            return (
                (combinations(20, z) * combinations(60, s_s)) /
                combinations(80, s)
            );
        },
        sumP(tableData) {
            let P = tableData[0];
            if (P) {
                let p = 0;
                Object.keys(P).forEach(key => {
                    if (typeof P[key] === "number" && key !== "X") {
                        p += P[key];
                    }
                });
                return p;
            }
            return 0;
        },
        sumE(tableData) {
            let E = tableData[1];
            if (E) {
                let e = 0;
                Object.keys(E).forEach(key => {
                    if (typeof E[key] === "number") {
                        e += E[key];
                    }
                });
                return e;
            }
            return 0;
        }
    }
};
</script>

<style scoped></style>
